import { PropsWithChildren } from 'react'

import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { Link } from '../link/link'

export type ObsoloteProductListItemWrapperProps = PropsWithChildren<{
  name?: string | null
  canonicalUrl?: string | null
  baseUrl: string
}>

export function ObsoloteProductListItemWrapper({
  name,
  canonicalUrl,
  children,
  baseUrl,
}: ObsoloteProductListItemWrapperProps): JSX.Element {
  return name ? (
    <Link
      title={name ?? 'product'}
      aria-label={`Link to the ${name}`}
      baseUrl={baseUrl}
      href={canonicalUrl ?? '/'}
      onClick={gtmTracker.trackRecommendedProductClick}
      className="[&:hover_.product-name]:underline max-h-[330px] sm:h-[330px]"
    >
      {children}
    </Link>
  ) : (
    <div className="max-h-[330px] sm:h-[330px]">{children}</div>
  )
}
